footer {
  height: auto;
  width: 100%;
  text-align: center;
  line-height: 1.25rem;
  padding: 1rem 0;

  .footer-container {
    font-size: 0.875rem;

    .footer-line {
      width: 100%;

      .footer-divider:not(:first-child)::before {
        content: '|';
        margin-left: 0.25rem;
        margin-right: 0.25rem;
      }

      .fixit-icon {
        top: 0.25em;
      }

      .animate-icon {
        color: #dc143c;
        animation: icon-animate 1.33s ease-in-out infinite;
      }

      .run-times {
        font-variant-numeric: tabular-nums lining-nums;
      }
    }
  }

  @include blur;
}

@keyframes icon-animate {
  0%,
  100% {
    transform: scale(1);
  }

  10%,
  30% {
    transform: scale(0.9);
  }

  20%,
  40%,
  60%,
  80% {
    transform: scale(1.1);
  }

  50%,
  70% {
    transform: scale(1.1);
  }
}
